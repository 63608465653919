<template>
  <ion-page data-pageid="navigation">
    <ion-header :translucent="true">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="relative h-30 bg-white">
        <nav
          class="flex items-center justify-between flex-wrap bg-black-500 p-6"
        >
          <div class="flex items-center flex-shrink-0 text-pink-600 mr-6">
            <router-link to="/">
              <img src="/assets/media/logo.jpg" alt="" class="w-44" />
            </router-link>
          </div>
          <div class="block lg:hidden">
            <button
              class="flex items-center px-3 py-2 border rounded text-black-200 border-black-400 hover:text-black hover:border-white"
            >
              <svg
                class="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            class="w-full block flex-grow lg:flex lg:items-center lg:w-auto"
            v-if="dataLoad"
          >
            <div class="text-sm lg:flex-grow">
              <span>
                <li>
                  <div
                    class="group inline-block"
                    v-for="mainCategory in mainCategories.data"
                    :key="mainCategory.id"
                  >
                    <button
                      class="outline-none focus:outline-none border px-5 py-2 bg-pink-600 rounded-lg flex items-center min-w-32"
                    >
                      <span class="pr-1 font-semibold flex-1">{{
                        mainCategory.attributes.title
                      }}</span>
                      <span>
                        <svg
                          class="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </span>
                    </button>
                    <ul
                      class="bg-pink-500 border rounded-lg transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32"
                    >
                      <span
                        v-for="category in mainCategory.attributes.categories
                          .data"
                        :key="category.id"
                      >
                        <div>
                          <li
                            class="rounded-lg relative px-3 py-1 hover:bg-pink-300"
                          >
                            <div
                              v-if="
                                category.attributes.subcategories.data.length >
                                0
                              "
                            >
                              <button
                                class="w-full text-left flex items-center outline-none focus:outline-none"
                              >
                                <span class="pr-1 flex-1">{{
                                  category.attributes.title
                                }}</span>
                                <span class="mr-auto">
                                  <svg
                                    class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>
                            <div v-else>
                              <button
                                class="w-full text-left flex items-center outline-none focus:outline-none"
                                @click="
                                  $router.push(
                                    `/articles/${category.id}?child=true`
                                  )
                                "
                              >
                                <span class="pr-1 flex-1">{{
                                  category.attributes.title
                                }}</span>
                                <span class="mr-auto">
                                  <svg
                                    class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </div>

                            <ul
                              class="bg-pink-500 border rounded-lg absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32"
                              v-if="
                                category.attributes.subcategories.data.length >
                                0
                              "
                            >
                              <span
                                v-for="subcategory in category.attributes
                                  .subcategories.data"
                                :key="subcategory.id"
                              >
                                <li
                                  class="rounded-sm relative px-3 py-1 hover:bg-pink-300"
                                >
                                  <div>
                                    <button
                                      class="w-full text-left flex items-center outline-none focus:outline-none"
                                      @click="
                                        $router.push(
                                          `/articles/${subcategory.id}`
                                        )
                                      "
                                    >
                                      <span class="pr-1 flex-1">{{
                                        subcategory.attributes.title
                                      }}</span>
                                      <span class="mr-auto">
                                        <svg
                                          class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                        >
                                          <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                                </li>
                              </span>
                            </ul>
                          </li>
                        </div>
                      </span>
                    </ul>
                  </div>
                </li>
              </span>
            </div>
          </div>
        </nav>
      </div>
    </ion-header>
    <ion-content>
      <div class="h-full flex flex-col">
        <div class="bg-white flex-1 p-4">
          <ul role="list" class="space-y-4" v-if="page">
            <!-- Duplicate -->
            <li class="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
              <article aria-labelledby="question-title-81614">
                <div>
                  <!-- Title -->
                  <h2
                    id="question-title-81614"
                    class="mt-4 text-base font-bold text-gray-900"
                  >
                    {{ page.data.attributes.title }}
                  </h2>
                </div>
                <div class="mt-2 text-sm text-gray-700 space-y-4">
                  <!-- Description -->
                  <Markdown :source="page.data.attributes.content" />
                </div>
              </article>
            </li>
          </ul>
        </div>
      </div>
      <ion-loading :is-open="isOpenRef" message="Please wait..."> </ion-loading>
    </ion-content>
    <ion-footer>
      <Footer />
    </ion-footer>
  </ion-page>
</template>

<script>
  import {
    IonPage,
    IonContent,
    IonHeader,
    IonLoading,
    IonFooter,
  } from "@ionic/vue";
  import { defineComponent, ref } from "vue";
  import Header from "./Header.vue";
  import moment from "moment";
  import { useRoute } from "vue-router";
  import Markdown from "vue3-markdown-it";
  import Footer from "./Footer.vue";

  export default defineComponent({
    components: {
      IonPage,
      Header,
      Footer,
      IonContent,
      IonHeader,
      IonLoading,
      Markdown,
      IonFooter,
    },
    setup() {
      const page = ref();
      const route = useRoute();
      const id = route.params.id;
      const isOpenRef = ref();
      const dataLoad = ref(false);
      const mainCategories = ref();
      fetch(`${process.env.VUE_APP_API_URL}/pages/${id}`)
        .then((res) => res.json())
        .then((data) => {
          page.value = data;
        })
        .finally(() => {
          isOpenRef.value = false;
        });

      fetch(
        `${process.env.VUE_APP_API_URL}/main-categories?populate[categories][populate]=*`
      )
        .then((res) => res.json())
        .then((data) => {
          mainCategories.value = data;
        })
        .finally(() => {
          dataLoad.value = true;
        });

      const apiURL = process.env.VUE_APP_API_URL;

      return { page, isOpenRef, moment, apiURL, mainCategories, dataLoad };
    },
  });
</script>
