<template>
  <ion-page data-pageid="navigation">
    <ion-header>
      <Header />
    </ion-header>
    <ion-content>
      <div class="backg">
        <div class="py-10 px-40 flex-1">
          <ul role="list" class="space-y-4" v-if="article">
            <!-- Duplicate -->
            <li class="bg-pink-100 px-40 py-40 shadow sm:p-6 sm:rounded-lg">
              <article aria-labelledby="question-title-81614">
                <div>
                  <!-- Title -->
                  <h2
                    id="question-title-81614"
                    class="mt-4 text-4xl font-bold text-gray-900"
                  >
                    {{ article.data.attributes.title }}
                  </h2>
                  <div class="flex space-x-3">
                    <div class="min-w-0 flex-1">
                      <p class="text-sm text-gray-500">
                        <time datetime="2020-12-09T11:43:00">{{
                          moment(article.data.attributes.createdAt).fromNow()
                        }}</time>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-2 text-sm text-gray-700 space-y-4">
                  <!-- Description -->
                  <Markdown :source="article.data.attributes.content" />
                </div>
              </article>
            </li>
          </ul>
        </div>
      </div>
      <ion-loading :is-open="isOpenRef" message="Please wait..."> </ion-loading>
    </ion-content>
    <ion-footer>
      <Footer />
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonFooter,
  IonContent,
  IonHeader,
  IonLoading,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import moment from "moment";
import { useRoute } from "vue-router";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    IonPage,
    Header,
    IonFooter,
    Footer,
    IonContent,
    IonHeader,
    IonLoading,
    Markdown,
  },
  setup() {
    const article = ref();
    const dataLoad = ref(false);
    const mainCategories = ref();
    const route = useRoute();
    const articleId = route.params.id;
    const isOpenRef = ref();
    fetch(
      `${process.env.VUE_APP_API_URL}/main-categories?populate[categories][populate]=*`
    )
      .then((res) => res.json())
      .then((data) => {
        mainCategories.value = data;
        fetch(`${process.env.VUE_APP_API_URL}/articles/${articleId}`)
          .then((res) => res.json())
          .then((data) => {
            article.value = data;
          })
          .finally(() => {
            isOpenRef.value = false;
          });
      })
      .finally(() => {
        dataLoad.value = true;
      });

    const apiURL = process.env.VUE_APP_API_URL;

    return { article, mainCategories, isOpenRef, dataLoad, moment, apiURL };
  },
});
</script>

<style>
.backg {
  height: 100%;
  background-image: url("https://api.4evayang.com/uploads/subtle_prism_b005e01c3d.svg");
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}
</style>
