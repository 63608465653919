import { createRouter, createWebHistory } from '@ionic/vue-router';
import Home from '../views/Home.vue'
import ArticleList from '../views/ArticleList.vue'
import ArticleDetail from '../views/ArticleDetail.vue'
import Page from '../views/Page.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/articles/:id',
    component: ArticleList,
  },
  {
    path: '/article/:id',
    component: ArticleDetail
  },
  {
    path: '/page/:id',
    component: Page
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history'
})

export default router
