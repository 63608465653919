<template>
  <ion-footer :translucent="true">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div
          class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
        >
          <div class="flex justify-center flex-1">
            <div class="flex justify-end">
              <img src="/assets/media/4eva.png" alt="" class="w-44" />
            </div>
          </div>
          <li class="mt-2 inline-block justify-end mr-2 sm:block sm:mr-0">
            <a href="/page/1" class="text-black hover:text-grey-dark"
              >Privacy</a
            >
          </li>
        </div>
      </div>

      <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    </div>
  </ion-footer>
</template>

<script>
  import { IonFooter } from "@ionic/vue";
  import { ref } from "@vue/reactivity";
  export default {
    components: { IonFooter },
    setup() {
      const openMenu = ref(false);

      return { openMenu };
    },
  };
</script>
