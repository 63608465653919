import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import Markdown from 'vue3-markdown-it';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import './theme/tailwind.css';



const app = createApp(App)
  .use(IonicVue)
  .use(router);
app.use(Markdown);
router.isReady().then(() => {
  app.mount('#app');
});