<template>
  <ion-page data-pageid="navigation">
    <ion-header>
      <Header />
    </ion-header>
    <ion-content>
      <div class="backg">
        <div class="py-10 px-40 flex-1">
          <ul role="list" class="space-y-4 max-h-screen" v-if="sub">
            <div v-if="sub?.data.length === 1">
              <li class="bg-pink-100 px-4 py-6 shadow sm:p-6 sm:rounded-lg">
                <article aria-labelledby="question-title-81614">
                  <div>
                    <!-- Title -->
                    <h2
                      id="question-title-81614"
                      class="mt-4 text-4xl font-bold text-gray-900"
                    >
                      {{ sub.data[0].attributes.title }}
                    </h2>
                    <div class="flex space-x-3">
                      <div class="min-w-0 flex-1">
                        <p class="text-sm text-gray-500">
                          <time datetime="2020-12-09T11:43:00">{{
                            moment(sub.data[0].attributes.createdAt).fromNow()
                          }}</time>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-2 text-sm text-gray-700 space-y-4"
                    v-if="sub.data[0]"
                  >
                    <ul>
                      <li>
                        <!-- Description -->
                        <Markdown :source="sub.data[0].attributes.content" />
                      </li>
                    </ul>
                  </div>
                </article>
              </li>
            </div>
            <div v-else-if="sub && sub.data.length > 1">
              <li
                class="bg-pink-100 px-4 py-6 shadow sm:p-6 sm:rounded-lg"
                v-for="article in sub.data"
                :key="article.id"
                @click="$router.push(`/article/${article.id}`)"
              >
                <article>
                  <div>
                    <!-- Title -->
                    <h2 class="mt-4 text-4xl font-bold text-gray-900">
                      {{ article.attributes.title }}
                    </h2>
                    <div class="flex space-x-3">
                      <div class="min-w-0 flex-1">
                        <p class="text-sm text-gray-500">
                          <time datetime="2020-12-09T11:43:00">{{
                            moment(article.attributes.createdAt).fromNow()
                          }}</time>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2 text-sm text-gray-700 space-y-4 mb-6">
                    <!-- Description -->
                    <Markdown :source="article.attributes.summary" />
                  </div>
                </article>
              </li>
            </div>
            <div v-else>
              <li
                class="bg-pink-100 text-center px-40 py-40 shadow sm:p-6 sm:rounded-lg"
              >
                <article aria-labelledby="question-title-81614">
                  <div>
                    <!-- Title -->
                    <h2
                      id="question-title-81614"
                      class="mt-4 text-md font-bold text-gray-900"
                    >
                      ARTICLE NOT FOUND
                    </h2>
                    <div class="flex space-x-3"></div>
                  </div>
                  <div class="mt-2 text-sm text-gray-700 space-y-4">
                    <!-- Description -->
                  </div>
                </article>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <ion-loading :is-open="isOpenRef" message="Please wait..."> </ion-loading>
    </ion-content>
    <ion-footer>
      <Footer />
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonFooter,
  IonLoading,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, watchEffect, nextTick } from "vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import moment from "moment";
import Markdown from "vue3-markdown-it";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    IonPage,
    Header,
    Footer,
    IonContent,
    IonHeader,
    IonLoading,
    IonFooter,
    Markdown,
  },

  setup() {
    const dataLoad = ref(false);
    const mainCategories = ref();
    const isOpenRef = ref(true);
    const sub = ref();
    const subDetail = ref();
    const route = useRoute();
    const child = route.query.child ? true : false;
    const subcategoryId = route.params.id;

    const getArticles = async (id, isChild) => {
      fetch(
        `${process.env.VUE_APP_API_URL}/main-categories?populate[categories][populate]=*`
      )
        .then((res) => res.json())
        .then(async (data) => {
          mainCategories.value = data;
          await fetch(
            `${process.env.VUE_APP_API_URL}${
              isChild ? "categories" : "subcategories"
            }/${id}?populate=*`
          )
            .then((res) => res.json())
            .then((data) => {
              sub.value = data.data.attributes.articles;
              console.log(sub.value.data.length);
              subDetail.value = data.data.attributes.title;
              console.log("sub", subDetail.value);
            })
            .finally(() => {
              isOpenRef.value = false;
            });
        })
        .finally(() => {
          isOpenRef.value = false;
        });
    };

    onMounted(async () => {
      await nextTick();
      setTimeout(() => {
        let links = document.querySelectorAll("a");
        let videoURLS = [];

        links.forEach((link) => {
          if (link.href.indexOf(".mp4") !== -1) {
            var videlem = document.createElement("video");
            var sourceMP4 = document.createElement("source");
            sourceMP4.type = "video/mp4";
            sourceMP4.src = link.href;
            videlem.controls = true;
            videlem.classList.add("w-full");
            videlem.classList.add("max-w-2xl");
            videlem.classList.add("mx-auto");
            videlem.classList.add("mb-4");
            videlem.appendChild(sourceMP4);
            link.classList.add("hidden");
            link.parentElement.classList.add("pb-4");
            link.parentElement.append(videlem);
          }
        });
      }, 1500);
    });

    onMounted(() => {
      isOpenRef.value = true;
      getArticles(route.params.id, route.query.child ? true : false).then(
        () => {
          watchEffect(() => {
            if (route.params.id) {
              isOpenRef.value = true;
              getArticles(route.params.id, route.query.child ? true : false);
            }
          });
        }
      );
    });

    return {
      dataLoad,
      isOpenRef,
      subDetail,
      mainCategories,
      subcategoryId,
      sub,
      moment,
    };
  },
});
</script>

<style>
.backg {
  height: 100%;
  background-image: url("https://api.4evayang.com/uploads/subtle_prism_b005e01c3d.svg");
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}
</style>
