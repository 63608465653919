<template>
  <ion-page data-pageid="navigation">
    <ion-header>
      <Header />
    </ion-header>
    <ion-content>
      <div
        class="backg"
        :style="{ 'background-image': 'url(' + url + ')' }"
      ></div>
    </ion-content>
    <ion-footer>
      <Footer />
    </ion-footer>
  </ion-page>
</template>

<script>
  import { ref } from "@vue/reactivity";
  import {
    IonPage,
    IonHeader,
    IonFooter,
    modalController,
    IonContent,
  } from "@ionic/vue";
  import { defineComponent } from "vue";
  import { useRouter } from "vue-router";
  import Header from "./Header.vue";
  import Footer from "./Footer.vue";

  export default defineComponent({
    components: {
      Header,
      Footer,
      IonPage,
      IonContent,
      IonHeader,
    },
    setup() {
      const router = useRouter();
      const openMenu = ref(false);
      const dataLoad = ref(false);
      const isOpenRef = ref(true);
      const url = ref();
      const homePage = ref();
      fetch(`${process.env.VUE_APP_API_URL}/main-pages/1?populate=*`)
        .then((res) => res.json())
        .then((data) => {
          homePage.value = data.data.attributes.mainpage.data.attributes.url;
          console.log(homePage.value);
          url.value = `${process.env.VUE_APP_SERVER_URL}${homePage.value}`;
          console.log(url.value);
        })
        .finally(() => {
          dataLoad.value = true;
          isOpenRef.value = false;
        });

      return { homePage, isOpenRef, openMenu, dataLoad, url, router };
    },
  });
</script>

<style>
  .backg {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
